import React from 'react';
import {
  BrowserRouter as Router,
  Route,
  Switch
  // Redirect
} from 'react-router-dom';
import * as Pages from './pageloader';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'react-phone-input-2/lib/style.css';
import './assets/css/main.css';
import './assets/css/reset.css';
import IdleTimerContainer from './SessionTimeout';
// import {PageNotFound} from 'pages/notfound/404'

const ToastConfig = {
  className: 'toast__container',
  bodyClassName: 'toast__body',
  closeOnClick: true,
  pauseOnHover: false,
  position: 'top-left'
};

// configure global toaster
toast.configure(ToastConfig);

const App = () => {
  return (
    <Router>
      <IdleTimerContainer />
      <Switch>
        {/* <Route exact path="/maintenance" component={Pages.Maintenance} />
        <Redirect from="/" to="/maintenance" /> */}
        <Route exact path="/login" component={Pages.LogIn} />
        <Route exact path="/" component={Pages.Home} />
        {/* <Redirect exact from="/" to="/login" /> */}
        <Route exact path="/get-started" component={Pages.SelectType} />
        <Route exact path="/verifyotp" component={Pages.VerifyOTP} />
        <Route path="/coming-soon" component={Pages.ComingSoon} />
        <Route exact path="/signup" component={Pages.SignUp} />
        {/* SCHOOL'S BASE ROUTES */}
        <Route exact path="/school-login" component={Pages.SchLogIn} />
        <Route
          exact
          path="/schforgotpassword"
          component={Pages.SchForgotPassword}
        />
        <Route exact path="/school-signup" component={Pages.SchSignUp} />
        <Route exact path="/school-signup2" component={Pages.SchSignUp2} />
        <Route exact path="/schverifyotp" component={Pages.SchOtp} />
        <Route exact path="/schsuccess" component={Pages.SchSuccess} />
        <Route path="/school-onboarding" component={Pages.SchoolFlow} />
        <Route path="/inprogress" component={Pages.InProgress} />
        <Route path="/school-dashboard" component={Pages.SchDashboard} />
        {/* END OF SCHOOL'S ROUTES */}
        {/*MFB*/}
        <Route path="/partner" component={Pages.MFB} />
        <Route path="/partner-login" component={Pages.MFBLogIn} />
        <Route
          exact
          path="/partner-verifyotp"
          component={Pages.PartnerVerifyOTP}
        />
        <Route
          exact
          path="/partner-passwordreset"
          component={Pages.PartnerPasswordReset}
        />
        <Route
          exact
          path="/partner-forgotpassword"
          component={Pages.PartnerForgotPassword}
        />
        {/* End of MFB */}

        <Route path="/savings" component={Pages.SavingsFlow} />
        <Route path="/loans" component={Pages.LoansFlow} />
        <Route path="/success" component={Pages.SignUpSuccess} />
        <Route path="/dashboard" component={Pages.Dashboard} />
        <Route path="/savingssuccess" component={Pages.SavingsSuccess} />
        <Route exact path="/forgotpassword" component={Pages.ForgotPassword} />
        <Route exact path="/passwordreset" component={Pages.PasswordReset} />
        <Route
          exact
          path="/termsandconditions"
          component={Pages.TermsAndConditions}
        />
        <Route exact path="/privacy" component={Pages.Privacy} />
        <Route exact path="/affiliated-school" component={Pages.MpowerSchool} />
        <Route exact path="/foreign-tuition" component={Pages.StudyAbroad} />

        {/* <Route exact path="/pagenotfound" component={PageNotFound} />
        <Route  path="">
          <Redirect to='/pagenotfound'/>
        </Route> */}
      </Switch>
    </Router>
  );
};

export default App;
