import axios from 'axios';
import { store } from 'store';
import { logout } from 'store/auth/actions';
import parseError from 'utils/ParseError';
// import { toastError } from 'utils/Toast';
import { updateApp } from './handleAppUpdate';
import * as Sentry from '@sentry/react';
import { toast } from 'react-toastify';

const request = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL
});

request.interceptors.request.use((config) => {
  const {
    auth: { data }
  } = store.getState();

  if (!data?.token) return config;

  const newConfig = {
    ...config,
    headers: {
      ...config.headers,
      Authorization: `Bearer ${data.token}`,
      'content-type': 'application/json'
    }
  };

  return newConfig;
});

// Add a response interceptor
request.interceptors.response.use(
  function (response) {
    updateApp(response);
    return response;
  },
  async function (error) {
    // Do something with response error
    if (error.response && error.response.status === 401) {
      store.dispatch(logout());
    }
    // const errorMessage = parseError(error);
    const { errorMessage, id } = parseError(error);

    if (errorMessage !== 'Network Error') {
      Sentry.captureMessage(
        'api middleWare :: unable to submit partner answers',
        {
          extra: {
            serverResponse: error.response.data
          }
        }
      );
    }
    // console.log(error.response.data);
    // toastError(errorMessage);
    toast.error(errorMessage, {
      toastId: id
    });
    return Promise.reject(error);
  }
);

export default request;
