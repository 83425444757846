import authActionTypes from './actionTypes';
import parseError from 'utils/ParseError';

const initialState = {
  data: null,
  loading: false,
  error: null,
  actionLoading: false
};

export const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case authActionTypes.SIGNUP.pending:
    case authActionTypes.SCHOOL_SIGNUP.pending:
    case authActionTypes.VERIFY_OTP.pending:
    case authActionTypes.SCHOOL_VERIFY_OTP.pending:
    case authActionTypes.LOGIN.pending:
    case authActionTypes.ONBOARD_SCHOOL.pending:
    case authActionTypes.SCHOOL_SIGNIN.pending:
    case authActionTypes.RESET_PASSWORD.pending:
    case authActionTypes.VERIFY_PASSWORD_RESET.pending:
    case authActionTypes.VERIFY_PASSWORD_RESET_OTP.pending:
    case authActionTypes.RESEND_REGISTRATION_OTP.pending:
    case authActionTypes.SIGNIN.pending: {
      return {
        ...state,
        loading: true,
        error: null
      };
    }

    case authActionTypes.SIGNUP.fulfilled:
    case authActionTypes.SCHOOL_SIGNUP.fulfilled:
    case authActionTypes.VERIFY_OTP.fulfilled:
    case authActionTypes.SCHOOL_VERIFY_OTP.fulfilled:
    case authActionTypes.SCHOOL_SIGNIN.fulfilled:
    case authActionTypes.LOGIN.fulfilled:
    case authActionTypes.SIGNIN.fulfilled: {
      return {
        ...state,
        loading: false,
        error: null,
        data: {
          token: action.payload.data.data.token,
          user: action.payload.data.data.user
        }
      };
    }
    case authActionTypes.ONBOARD_SCHOOL.fulfilled: {
      return {
        ...state,
        loading: false,
        error: null,
        data: {
          token: action.payload.data.data.token,
          school: action.payload.data.data.school
        }
      };
    }

    case authActionTypes.RESET_PASSWORD.fulfilled:
    case authActionTypes.VERIFY_PASSWORD_RESET.fulfilled:
    case authActionTypes.VERIFY_PASSWORD_RESET_OTP.fulfilled:
    case authActionTypes.RESEND_REGISTRATION_OTP.fulfilled: {
      return {
        ...state,
        loading: false,
        error: null
      };
    }

    case authActionTypes.SIGNUP.rejected:
    case authActionTypes.VERIFY_OTP.rejected:
    case authActionTypes.ONBOARD_SCHOOL.rejected:
    case authActionTypes.SCHOOL_VERIFY_OTP.rejected:
    case authActionTypes.SCHOOL_SIGNUP.rejected:
    case authActionTypes.LOGIN.rejected:
    case authActionTypes.SIGNIN.rejected:
    case authActionTypes.RESET_PASSWORD.rejected:
    case authActionTypes.VERIFY_PASSWORD_RESET.rejected:
    case authActionTypes.SCHOOL_SIGNIN.rejected:
    case authActionTypes.RESEND_REGISTRATION_OTP.rejected: {
      return {
        ...state,
        loading: false,
        error: parseError(action.payload)
      };
    }

    case authActionTypes.RESET: {
      return initialState;
    }

    case authActionTypes.UPDATE_PROFILE.pending:
    case authActionTypes.CHANGE_PASSWORD.pending: {
      return {
        ...state,
        actionLoading: true,
        error: null
      };
    }

    case authActionTypes.UPDATE_PROFILE.fulfilled:
    case authActionTypes.CHANGE_PASSWORD.fulfilled: {
      return {
        ...state,
        actionLoading: false,
        error: null
      };
    }

    case authActionTypes.UPDATE_PROFILE.rejected:
    case authActionTypes.CHANGE_PASSWORD.rejected: {
      return {
        ...state,
        actionLoading: false,
        error: parseError(action.payload)
      };
    }

    default:
      return state;
  }
};

export default authReducer;
