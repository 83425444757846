import accountActionTypes from './actionTypes';
import parseError from 'utils/ParseError';

const initialState = {
  actionLoading: false,
  error: null,
  school: null,
  confirmations: null,
  upload: null,
  studentConfirmation: null,
  disbursements: null
};

export const schoolReducer = (state = initialState, action) => {
  switch (action.type) {
    case accountActionTypes.CREAT_SCHOOL.pending:
    case accountActionTypes.GET_SCHOOL.pending:
    case accountActionTypes.SCHOOL_DOCUMENT.pending:
    case accountActionTypes.SCHOOL_DOCUMENT_RECIEPT.pending:
    case accountActionTypes.UPDATE_SCHOOL.pending:
    case accountActionTypes.CONFIRM_STUDENT.pending:
    case accountActionTypes.GET_DISBUREMENTS.pending:
    case accountActionTypes.GET_CONFIRMATIONS.pending:
    case accountActionTypes.CREATE_SCHOOL_BANK_ACCOUNT.pending: {
      return {
        ...state,
        actionLoading: true,
        error: null
      };
    }
    case accountActionTypes.CREAT_SCHOOL.fulfilled:
    case accountActionTypes.CREATE_SCHOOL_BANK_ACCOUNT.fulfilled: {
      return {
        ...state,
        actionLoading: false,
        error: null
      };
    }
    case accountActionTypes.UPDATE_SCHOOL.fulfilled:
    case accountActionTypes.GET_SCHOOL.fulfilled: {
      return {
        ...state,
        actionLoading: false,
        error: null,
        school: action.payload.data.data.school
      };
    }
    case accountActionTypes.GET_CONFIRMATIONS.fulfilled: {
      return {
        ...state,
        actionLoading: false,
        error: null,
        confirmations: action.payload.data.data.confirmations
      };
    }

    case accountActionTypes.CONFIRM_DISBUREMENTS.fulfilled:
    case accountActionTypes.GET_DISBUREMENTS.fulfilled: {
      return {
        ...state,
        actionLoading: false,
        error: null,
        disbursements: action.payload.data.data.disbursements
      };
    }
    case accountActionTypes.SCHOOL_DOCUMENT.fulfilled:
    case accountActionTypes.SCHOOL_DOCUMENT_RECIEPT.fulfilled: {
      return {
        ...state,
        actionLoading: false,
        error: null,
        upload: action.payload.data.data.upload
      };
    }
    case accountActionTypes.CONFIRM_STUDENT.fulfilled: {
      return {
        ...state,
        actionLoading: false,
        error: null,
        studentConfirmation: action.payload.data.data.student_confirmation
      };
    }

    case accountActionTypes.GET_DISBUREMENTS.rejected:
    case accountActionTypes.CREAT_SCHOOL.rejected:
    case accountActionTypes.UPDATE_SCHOOL.rejected:
    case accountActionTypes.SCHOOL_DOCUMENT.rejected:
    case accountActionTypes.GET_CONFIRMATIONS.rejected:
    case accountActionTypes.GET_SCHOOL.rejected:
    case accountActionTypes.SCHOOL_DOCUMENT_RECIEPT.rejected:
    case accountActionTypes.CREATE_SCHOOL_BANK_ACCOUNT.rejected: {
      return {
        ...state,
        actionLoading: false,
        error: parseError(action.payload)
      };
    }
    default:
      return state;
  }
};

export default schoolReducer;
