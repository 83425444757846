import actionTypes from 'utils/actionTypes';

export default {
  LOAN_RECEIPT: actionTypes('LOAN_RECEIPT'),
  LOAN_COMMENT: actionTypes('LOAN_COMMENT'),
  CREDIT_CHECK: actionTypes('CREDIT_CHECK'),
  LOAN_ACTIVITY: actionTypes('LOAN_ACTIVITY'),
  COUNTER_OFFER: actionTypes('COUNTER_OFFER'),
  LOAN_APPROVED: actionTypes('LOAN_APPROVED'),
  LOAN_DISBURSE: actionTypes('LOAN_DISBURSE'),
  LOAN_DOCUMENT: actionTypes('LOAN_DOCUMENT'),
  LOAN_DECISION: actionTypes('LOAN_DECISION'),
  LOAN_REPAYMENT: actionTypes('LOAN_REPAYMENT'),
  LOAN_DECISION_POOL: actionTypes('LOAN_DECISION_POOL'),
  LOAN_MANUAL_REPAYMENT: actionTypes('LOAN_MANUAL_REPAYMENT')
};
