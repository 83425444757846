import accountActionTypes from './actionTypes';
import parseError from 'utils/ParseError';

const initialState = {
  accounts: null,
  account: null,
  banks: null,
  loading: false,
  error: null,
  loadingBankDetails: false,
  loadingBvnVerification: false,
  actionLoading: false,
  bankDetails: null,
  bvnDetails: null
};

export const paymentReducer = (state = initialState, action) => {
  switch (action.type) {
    case accountActionTypes.GET_BANKS.pending:
    case accountActionTypes.GET_ACCOUNTS.pending:
    case accountActionTypes.GET_ACCOUNT.pending: {
      return {
        ...state,
        loading: true,
        error: null
      };
    }
    case accountActionTypes.RESOLVE_BANK.pending: {
      return {
        ...state,
        loadingBankDetails: true,
        error: null
      };
    }

    case accountActionTypes.VERIFY_BVN.pending: {
      return {
        ...state,
        loadingBvnVerification: true,
        error: null
      };
    }
    case accountActionTypes.VERIFY_BVN.fulfilled: {
      return {
        ...state,
        loadingBvnVerification: false,
        error: null,
        bvnDetails: action.payload.data.data
      };
    }
    case accountActionTypes.VERIFY_BVN.rejected: {
      return {
        ...state,
        loadingBvnVerification: false,
        error: parseError(action.payload)
      };
    }

    case accountActionTypes.RESOLVE_BANK.fulfilled: {
      return {
        ...state,
        loadingBankDetails: false,
        error: null,
        bankDetails: action.payload.data.data
      };
    }
    case accountActionTypes.GET_BANKS.fulfilled: {
      return {
        ...state,
        loading: false,
        error: null,
        banks: action.payload.data.data
      };
    }
    case accountActionTypes.GET_ACCOUNTS.fulfilled: {
      return {
        ...state,
        loading: false,
        error: null,
        accounts: action.payload.data.data
      };
    }
    case accountActionTypes.GET_ACCOUNT.fulfilled: {
      return {
        ...state,
        loading: false,
        error: null,
        account: action.payload.data.data
      };
    }
    case accountActionTypes.GET_BANKS.rejected:
    case accountActionTypes.GET_ACCOUNTS.rejected:
    case accountActionTypes.GET_ACCOUNT.rejected: {
      return {
        ...state,
        loading: false,
        error: parseError(action.payload)
      };
    }
    case accountActionTypes.RESOLVE_BANK.rejected: {
      return {
        ...state,
        loadingBankDetails: false,
        error: parseError(action.payload)
      };
    }
    case accountActionTypes.ADD_ACCOUNT.pending:
    case accountActionTypes.UPDATE_DEFAULT_ACCOUNT.pending:
    case accountActionTypes.ADD_ACCOUNT_REQUEST.pending: {
      return {
        ...state,
        actionLoading: true,
        error: null
      };
    }
    case accountActionTypes.ADD_ACCOUNT.fulfilled:
    case accountActionTypes.UPDATE_DEFAULT_ACCOUNT.fulfilled:
    case accountActionTypes.ADD_ACCOUNT_REQUEST.fulfilled: {
      return {
        ...state,
        actionLoading: false,
        error: null
      };
    }
    case accountActionTypes.ADD_ACCOUNT.rejected:
    case accountActionTypes.UPDATE_DEFAULT_ACCOUNT.rejected:
    case accountActionTypes.ADD_ACCOUNT_REQUEST.rejected: {
      return {
        ...state,
        actionLoading: false,
        error: parseError(action.payload)
      };
    }
    default:
      return state;
  }
};

export default paymentReducer;
