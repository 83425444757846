import loanActionTypes from './actionTypes';
import parseError from 'utils/ParseError';

const initialState = {
  data: null,
  repaymentHistory: null,
  repaymentAmount: null,
  loading: false,
  error: null,
  actionLoading: false,
  loans: null,
  loan: null,
  states: [],
  schools: []
};

export const loanReducer = (state = initialState, action) => {
  switch (action.type) {
    case loanActionTypes.RESET: {
      return initialState;
    }
    case loanActionTypes.FOREIGN_TUITION.pending:
    case loanActionTypes.GET_STATES.pending:
    case loanActionTypes.FOREIGN_SCHOOLS.pending:
    case loanActionTypes.LOAN_DETAILS.pending:
    case loanActionTypes.SUBMIT_STAGE_ONE.pending:
    case loanActionTypes.CREATE_LOAN_APPLICATION.pending:
    case loanActionTypes.UPDATE_WARD_DETAILS.pending:
    case loanActionTypes.REPAYMENT_DETAILS.pending:
    case loanActionTypes.REPAYMENT_HISTORY.pending:
    case loanActionTypes.REPAYMENT_AMOUNT.pending:
    case loanActionTypes.GET_LOAN_BYID.pending:
    case loanActionTypes.EMPLOYMENT_DETAILS.pending:
    case loanActionTypes.ROLLBACK.pending:
    case loanActionTypes.SAVE_BVN.pending:
    case loanActionTypes.ANSWER_LOAN_PARTNER.pending:
    case loanActionTypes.INITIALIZE_SIGNATURE.pending:
    case loanActionTypes.VERIFY_SIGNATURE.pending:
    case loanActionTypes.DELETE_LOAN.pending:
    case loanActionTypes.PAY_NEXT_INSTALLMENT.pending:
    case loanActionTypes.VALIDATE_BVN.pending:
    case loanActionTypes.COUNTER_DECISION.pending:
    case loanActionTypes.PAY_OUTSTANDING.pending: {
      return {
        ...state,
        actionLoading: true,
        error: null
      };
    }
    case loanActionTypes.FOREIGN_SCHOOLS.fulfilled: {
      return {
        ...state,
        actionLoading: false,
        schools: action.payload.data.data
      };
    }
    case loanActionTypes.INITIALIZE_SIGNATURE.fulfilled:
    case loanActionTypes.SUBMIT_STAGE_ONE.fulfilled:
    case loanActionTypes.VERIFY_SIGNATURE.fulfilled:
    case loanActionTypes.FOREIGN_TUITION.fulfilled:
    case loanActionTypes.ROLLBACK.fulfilled:
    case loanActionTypes.DELETE_LOAN.fulfilled:
    case loanActionTypes.PAY_NEXT_INSTALLMENT.fulfilled:
    case loanActionTypes.VALIDATE_BVN.fulfilled:
    case loanActionTypes.COUNTER_DECISION.fulfilled:
    case loanActionTypes.PAY_OUTSTANDING.fulfilled: {
      return {
        ...state,
        actionLoading: false,
        error: null
      };
    }
    case loanActionTypes.GET_LOAN_BYID.fulfilled: {
      return {
        ...state,
        actionLoading: false,
        error: null,
        loan: action.payload.data.data
      };
    }
    case loanActionTypes.GET_STATES.fulfilled: {
      return {
        ...state,
        actionLoading: false,
        error: null,
        states: action.payload.data.data.available_states
      };
    }
    case loanActionTypes.REPAYMENT_HISTORY.fulfilled: {
      return {
        ...state,
        actionLoading: false,
        error: null,
        repaymentHistory: action.payload.data.data
      };
    }
    case loanActionTypes.REPAYMENT_AMOUNT.fulfilled: {
      return {
        ...state,
        actionLoading: false,
        error: null,
        repaymentAmount: action.payload.data.data
      };
    }
    case loanActionTypes.CREATE_LOAN_APPLICATION.fulfilled:
    case loanActionTypes.UPDATE_WARD_DETAILS.fulfilled:
    case loanActionTypes.LOAN_DETAILS.fulfilled:
    case loanActionTypes.REPAYMENT_DETAILS.fulfilled:
    case loanActionTypes.EMPLOYMENT_DETAILS.fulfilled:
    case loanActionTypes.SAVE_BVN.fulfilled:
    case loanActionTypes.ANSWER_LOAN_PARTNER.fulfilled: {
      return {
        ...state,
        actionLoading: false,
        error: null,
        data: action.payload.data.data
      };
    }
    case loanActionTypes.CREATE_LOAN_APPLICATION.rejected:
    case loanActionTypes.FOREIGN_TUITION.rejected:
    case loanActionTypes.UPDATE_WARD_DETAILS.rejected:
    case loanActionTypes.GET_STATES.rejected:
    case loanActionTypes.SUBMIT_STAGE_ONE.rejected:
    case loanActionTypes.ROLLBACK.rejected:
    case loanActionTypes.LOAN_DETAILS.rejected:
    case loanActionTypes.REPAYMENT_DETAILS.rejected:
    case loanActionTypes.REPAYMENT_HISTORY.rejected:
    case loanActionTypes.REPAYMENT_AMOUNT.rejected:
    case loanActionTypes.GET_LOAN_BYID.rejected:
    case loanActionTypes.EMPLOYMENT_DETAILS.rejected:
    case loanActionTypes.SAVE_BVN.rejected:
    case loanActionTypes.ANSWER_LOAN_PARTNER.rejected:
    case loanActionTypes.INITIALIZE_SIGNATURE.rejected:
    case loanActionTypes.VERIFY_SIGNATURE.rejected:
    case loanActionTypes.DELETE_LOAN.rejected:
    case loanActionTypes.PAY_NEXT_INSTALLMENT.rejected:
    case loanActionTypes.VALIDATE_BVN.rejected:
    case loanActionTypes.COUNTER_DECISION.rejected:
    case loanActionTypes.PAY_OUTSTANDING.rejected: {
      return {
        ...state,
        actionLoading: false,
        error: parseError(action.payload)
      };
    }
    case loanActionTypes.GET_LOANS.pending: {
      return {
        ...state,
        loading: true,
        error: null
      };
    }
    case loanActionTypes.GET_LOANS.fulfilled: {
      return {
        ...state,
        loading: false,
        error: null,
        loans: action.payload.data.data
      };
    }
    case loanActionTypes.GET_LOANS.rejected: {
      return {
        ...state,
        loading: false,
        error: parseError(action.payload)
      };
    }
    default:
      return state;
  }
};

export default loanReducer;
