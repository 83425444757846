import { combineReducers } from 'redux';
import authReducer from './auth';
import wardReducer from './ward';
import paymentReducer from './payments';
import savingsReducer from './savings';
import accountsReducer from './accounts';
import userReducer from './user';
import uploadReducer from './uploads';
import loanReducer from './loan';
import customReducer from './custom';
import loanPartnerReducer from './loanPartner';
import transactionReducer from './transactions';
import walletReduce from './wallet';
import schoolReducer from './school';
import loanReceiptReducer from './mfb';

export default combineReducers({
  auth: authReducer,
  ward: wardReducer,
  payment: paymentReducer,
  savings: savingsReducer,
  accounts: accountsReducer,
  user: userReducer,
  upload: uploadReducer,
  loan: loanReducer,
  custom: customReducer,
  loanPartner: loanPartnerReducer,
  transaction: transactionReducer,
  wallet: walletReduce,
  school: schoolReducer,
  loanReceipt: loanReceiptReducer
});
