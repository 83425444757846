import actionTypes from 'utils/actionTypes';

export default {
  GET_SCHOOL: actionTypes('GET_SCHOOL'),
  CREAT_SCHOOL: actionTypes('CREAT_SCHOOL'),
  UPDATE_SCHOOL: actionTypes('UPDATE_SCHOOL'),
  CONFIRM_STUDENT: actionTypes('CONFIRM_STUDENT'),
  SCHOOL_DOCUMENT: actionTypes('SCHOOL_DOCUMENT'),
  SCHOOL_DOCUMENT_RECIEPT: actionTypes('SCHOOL_DOCUMENT_RECIEPT'),
  CONFIRM_DISBUREMENTS: actionTypes('CONFIRM_DISBUREMENTS'),
  GET_DISBUREMENTS: actionTypes('GET_DISBUREMENTS'),
  GET_CONFIRMATION: actionTypes('GET_CONFIRMATION'),
  GET_CONFIRMATIONS: actionTypes('GET_CONFIRMATIONS'),
  CREATE_SCHOOL_BANK_ACCOUNT: actionTypes('CREATE_SCHOOL_BANK_ACCOUNT')
};
