import loadable from '@loadable/component';
import Preloader from './preloader';

export const Home = loadable(() => import('pages/home'), {
  LoadingComponent: Preloader
});

export const SelectType = loadable(
  () => import('pages/onboarding/selectType'),
  {
    LoadingComponent: Preloader
  }
);

export const TermsAndConditions = loadable(
  () => import('pages/home_old/terms&conditions'),
  {
    LoadingComponent: Preloader
  }
);

export const MpowerSchool = loadable(() => import('pages/home_old/MPower'), {
  LoadingComponent: Preloader
});
export const Privacy = loadable(() => import('pages/home_old/privacy'), {
  LoadingComponent: Preloader
});
export const VerifyOTP = loadable(() => import('pages/onboarding/verifyOtp'), {
  LoadingComponent: Preloader
});

export const SignUpSuccess = loadable(
  () => import('pages/onboarding/success'),
  {
    LoadingComponent: Preloader
  }
);

export const SignUp = loadable(() => import('pages/onboarding/signUp'), {
  LoadingComponent: Preloader
});

export const LogIn = loadable(() => import('pages/onboarding/logIn'), {
  LoadingComponent: Preloader
});

export const Maintenance = loadable(
  () => import('pages/onboarding/maintenance'),
  {
    LoadingComponent: Preloader
  }
);

// School onboarding
export const SchLogIn = loadable(() => import('pages/schoolOnBoarding/logIn'), {
  LoadingComponent: Preloader
});

export const SchForgotPassword = loadable(
  () => import('pages/schoolOnBoarding/forgotPassword'),
  {
    LoadingComponent: Preloader
  }
);

export const SchSignUp = loadable(
  () => import('pages/schoolOnBoarding/signUp'),
  {
    LoadingComponent: Preloader
  }
);
export const SchSignUp2 = loadable(
  () => import('pages/schoolOnBoarding/signUpContact'),
  {
    LoadingComponent: Preloader
  }
);
export const SchOtp = loadable(
  () => import('pages/schoolOnBoarding/verifyOtp'),
  {
    LoadingComponent: Preloader
  }
);

export const SchSuccess = loadable(
  () => import('pages/schoolOnBoarding/success'),
  {
    LoadingComponent: Preloader
  }
);
export const SchDashboard = loadable(() => import('pages/schDashboard'), {
  LoadingComponent: Preloader
});

export const SchoolFlow = loadable(() => import('pages/schoolForm/index'), {
  LoadingComponent: Preloader
});

export const InProgress = loadable(
  () => import('pages/schoolForm/components/VerificationInProgress'),
  {
    LoadingComponent: Preloader
  }
);
// MFB
export const MFB = loadable(() => import('pages/mfb'), {
  LoadingComponent: Preloader
});
export const MFBLogIn = loadable(() => import('pages/mfb/SignIn'), {
  LoadingComponent: Preloader
});
export const PartnerVerifyOTP = loadable(() => import('pages/mfb/verifyOtp'), {
  LoadingComponent: Preloader
});
export const PartnerForgotPassword = loadable(() =>
  import('pages/mfb/forgotPassword')
);

export const PartnerPasswordReset = loadable(() =>
  import('pages/mfb/passwordReset')
);
// End of MFB
// study overseas
export const StudyAbroad = loadable(() => import('pages/study_abroad'), {
  LoadingComponent: Preloader
});
// end

export const PageNotFound = loadable(() => import('pages/notfound/404'));

export const Dashboard = loadable(() => import('pages/dashboard'), {
  LoadingComponent: Preloader
});

export const SavingsFlow = loadable(() => import('pages/savings'), {
  LoadingComponent: Preloader
});

export const SavingsSuccess = loadable(() => import('pages/savings/success'), {
  LoadingComponent: Preloader
});

export const LoansFlow = loadable(() => import('pages/loan'), {
  LoadingComponent: Preloader
});

export const ComingSoon = loadable(() => import('pages/onboarding/comingSoon'));

export const ForgotPassword = loadable(() =>
  import('pages/onboarding/forgotPassword')
);

export const PasswordReset = loadable(() =>
  import('pages/onboarding/passwordReset')
);

//for more info about use of loadable-components -> https://github.com/gregberge/loadable-components
