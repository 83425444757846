import savingsActionTypes from './actionTypes';
import parseError from 'utils/ParseError';
import { sortInDescendingOrderOfCreatedAt } from 'utils/sortPlans';

const initialState = {
  data: null,
  completedPlans: null,
  activePlans: null,
  pocketMoneyPlans: null,
  pocketMoneyPlan: null,
  notifications: null,
  loading: false,
  error: null,
  actionLoading: false,
  loadingAnalytics: false,
  analytics: null
};

export const savingsReducer = (state = initialState, action) => {
  switch (action.type) {
    case savingsActionTypes.GET_PLANS.pending:
    case savingsActionTypes.GET_POCKET_MONEY_PLANS.pending:
    case savingsActionTypes.GET_POCKET_MONEY_PLAN.pending:
    case savingsActionTypes.GET_ACTIVE_PLANS.pending:
    case savingsActionTypes.GET_COMPLETED_PLANS.pending: {
      return {
        ...state,
        loading: true,
        error: null
      };
    }
    case savingsActionTypes.GET_PLANS.fulfilled: {
      return {
        ...state,
        loading: false,
        data: action.payload.data.data?.sort(sortInDescendingOrderOfCreatedAt)
      };
    }
    case savingsActionTypes.GET_ACTIVE_PLANS.fulfilled: {
      return {
        ...state,
        loading: false,
        activePlans: action.payload.data.data?.sort(
          sortInDescendingOrderOfCreatedAt
        )
      };
    }
    case savingsActionTypes.GET_COMPLETED_PLANS.fulfilled: {
      return {
        ...state,
        loading: false,
        completedPlans: action.payload.data.data?.sort(
          sortInDescendingOrderOfCreatedAt
        )
      };
    }
    case savingsActionTypes.GET_POCKET_MONEY_PLANS.fulfilled: {
      return {
        ...state,
        loading: false,
        pocketMoneyPlans: action.payload.data.data?.sort(
          sortInDescendingOrderOfCreatedAt
        )
      };
    }
    case savingsActionTypes.GET_POCKET_MONEY_PLAN.fulfilled: {
      return {
        ...state,
        loading: false,
        pocketMoneyPlan: action.payload.data.data
      };
    }
    case savingsActionTypes.GET_PLANS.rejected:
    case savingsActionTypes.GET_POCKET_MONEY_PLANS.rejected:
    case savingsActionTypes.GET_POCKET_MONEY_PLAN.rejected:
    case savingsActionTypes.GET_ACTIVE_PLANS.rejected:
    case savingsActionTypes.GET_COMPLETED_PLANS.rejected: {
      return {
        ...state,
        loading: false,
        error: parseError(action.payload)
      };
    }
    case savingsActionTypes.CREATE_PLAN.pending:
    case savingsActionTypes.CREATE_POCKET_MONEY_PLAN.pending:
    case savingsActionTypes.DELETE_POCKET_MONEY_PLAN.pending:
    case savingsActionTypes.WITHDRAW_FUNDS.pending: {
      return {
        ...state,
        actionLoading: true,
        error: null
      };
    }
    case savingsActionTypes.CREATE_PLAN.fulfilled:
    case savingsActionTypes.CREATE_POCKET_MONEY_PLAN.fulfilled:
    case savingsActionTypes.DELETE_POCKET_MONEY_PLAN.fulfilled:
    case savingsActionTypes.WITHDRAW_FUNDS.fulfilled: {
      return {
        ...state,
        actionLoading: false,
        error: null
      };
    }
    case savingsActionTypes.CREATE_PLAN.rejected:
    case savingsActionTypes.CREATE_POCKET_MONEY_PLAN.rejected:
    case savingsActionTypes.DELETE_POCKET_MONEY_PLAN.rejected:
    case savingsActionTypes.WITHDRAW_FUNDS.rejected: {
      return {
        ...state,
        actionLoading: false,
        error: parseError(action.payload)
      };
    }
    case savingsActionTypes.UPDATE_PLAN.pending:
    case savingsActionTypes.UPDATE_POCKET_MONEY_PLAN.pending: {
      return {
        ...state,
        actionLoading: true,
        error: null
      };
    }
    case savingsActionTypes.UPDATE_PLAN.fulfilled:
    case savingsActionTypes.UPDATE_POCKET_MONEY_PLAN.fulfilled: {
      return {
        ...state,
        actionLoading: false,
        error: null
      };
    }
    case savingsActionTypes.UPDATE_PLAN.rejected:
    case savingsActionTypes.UPDATE_POCKET_MONEY_PLAN.rejected: {
      return {
        ...state,
        actionLoading: false,
        error: parseError(action.payload)
      };
    }
    case savingsActionTypes.FUND_PLAN.pending:
    case savingsActionTypes.FUND_POCKET_MONEY_PLAN.pending: {
      return {
        ...state,
        loadingFundPlan: true,
        error: null
      };
    }
    case savingsActionTypes.FUND_PLAN.fulfilled:
    case savingsActionTypes.FUND_POCKET_MONEY_PLAN.fulfilled: {
      return {
        ...state,
        loadingFundPlan: false,
        error: null
      };
    }
    case savingsActionTypes.FUND_PLAN.rejected:
    case savingsActionTypes.FUND_POCKET_MONEY_PLAN.rejected: {
      return {
        ...state,
        loadingFundPlan: false,
        error: parseError(action.payload)
      };
    }
    case savingsActionTypes.PLAN_ANALYTICS.pending: {
      return {
        ...state,
        loadingAnalytics: true,
        error: null
      };
    }
    case savingsActionTypes.PLAN_ANALYTICS.fulfilled: {
      return {
        ...state,
        loadingAnalytics: false,
        analytics: action.payload.data.data
      };
    }
    case savingsActionTypes.PLAN_ANALYTICS.rejected: {
      return {
        ...state,
        loadingAnalytics: false,
        error: parseError(action.payload)
      };
    }
    case savingsActionTypes.PLANS:
      return {
        ...state,
        allPlans: []
          .concat(state.activePlans || [], state.completedPlans || [])
          .sort(sortInDescendingOrderOfCreatedAt)
      };
    case savingsActionTypes.CREATE_NOTIFICATION:
      return {
        ...state,
        notification: action.payload
      };

    // case savingsActionTypes.CREATE_POCKET_MONEY_PLAN.fulfilled: {
    //   return {
    //     ...state,
    //     actionLoading: false,
    //     error: null
    //   };
    // }
    default:
      return state;
  }
};

export default savingsReducer;
