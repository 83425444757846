import wardActionTypes from './actionTypes';
import parseError from 'utils/ParseError';

const initialState = {
  data: null,
  wardData: null,
  loading: false,
  error: null,
  actionLoading: false,
  schools: null
};

export const wardReducer = (state = initialState, action) => {
  switch (action.type) {
    case wardActionTypes.GETWARDS.pending:
    case wardActionTypes.LOAN_WARDS.pending: {
      return {
        ...state,
        loading: true,
        error: null
      };
    }
    case wardActionTypes.GETWARDS.fulfilled: {
      return {
        ...state,
        loading: false,
        error: null,
        data: action.payload.data.data
      };
    }
    case wardActionTypes.LOAN_WARDS.fulfilled: {
      return {
        ...state,
        loading: false,
        error: null,
        wardData: action.payload
      };
    }
    case wardActionTypes.GETWARDS.rejected:
    case wardActionTypes.LOAN_WARDS.rejected: {
      return {
        ...state,
        loading: false,
        error: parseError(action.payload)
      };
    }
    case wardActionTypes.ADDWARD.pending:
    case wardActionTypes.DELETE_WARD.pending:
    case wardActionTypes.UPDATE_WARD.pending: {
      return {
        ...state,
        actionLoading: true,
        error: null
      };
    }
    case wardActionTypes.ADDWARD.fulfilled:
    case wardActionTypes.DELETE_WARD.fulfilled:
    case wardActionTypes.UPDATE_WARD.fulfilled: {
      return {
        ...state,
        actionLoading: false,
        error: null
      };
    }
    case wardActionTypes.ADDWARD.rejected:
    case wardActionTypes.DELETE_WARD.rejected:
    case wardActionTypes.UPDATE_WARD.rejected: {
      return {
        ...state,
        actionLoading: false,
        error: parseError(action.payload)
      };
    }
    case wardActionTypes.GET_SCHOOLS.pending: {
      return {
        ...state,
        error: null
      };
    }
    case wardActionTypes.GET_SCHOOLS.fulfilled: {
      return {
        ...state,
        error: null,
        schools: action.payload.data.data.schools
      };
    }
    case wardActionTypes.GET_SCHOOLS.rejected: {
      return {
        ...state,
        error: parseError(action.payload)
      };
    }
    default:
      return state;
  }
};

export default wardReducer;
