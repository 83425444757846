import transactionActionTypes from './actionTypes';
import parseError from 'utils/ParseError';

const initialState = {
  data: null,
  loading: false,
  loadingWalletCreditTransactions: false,
  loadingWalletDebitTransactions: false,
  walletCreditTransactions: null,
  walletDebitTransactions: null,
  error: null
};

export const transactionsReducer = (state = initialState, action) => {
  switch (action.type) {
    case transactionActionTypes.GETTRANSACTIONS.pending: {
      return {
        ...state,
        loading: true,
        error: null
      };
    }
    case transactionActionTypes.GETWALLETCREDITTRANSACTIONS.pending: {
      return {
        ...state,
        loadingWalletCreditTransactions: true,
        error: null
      };
    }
    case transactionActionTypes.GETWALLETDEBITTRANSACTIONS.pending: {
      return {
        ...state,
        loadingWalletDebitTransactions: true,
        error: null
      };
    }
    case transactionActionTypes.GETTRANSACTIONS.fulfilled: {
      return {
        ...state,
        loading: false,
        error: null,
        data: action.payload.data.data
      };
    }
    case transactionActionTypes.GETWALLETCREDITTRANSACTIONS.fulfilled: {
      return {
        ...state,
        loadingWalletCreditTransactions: false,
        error: null,
        walletCreditTransactions: action.payload.data.data
      };
    }
    case transactionActionTypes.GETWALLETDEBITTRANSACTIONS.fulfilled: {
      return {
        ...state,
        loadingWalletDebitTransactions: false,
        error: null,
        walletDebitTransactions: action.payload.data.data
      };
    }
    case transactionActionTypes.GETTRANSACTIONS.rejected: {
      return {
        ...state,
        loading: false,
        error: parseError(action.payload)
      };
    }
    case transactionActionTypes.GETWALLETCREDITTRANSACTIONS.rejected: {
      return {
        ...state,
        loadingWalletCreditTransactions: false,
        error: parseError(action.payload)
      };
    }
    case transactionActionTypes.GETWALLETDEBITTRANSACTIONS.rejected: {
      return {
        ...state,
        loadingWalletDebitTransactions: false,
        error: parseError(action.payload)
      };
    }
    default:
      return state;
  }
};

export default transactionsReducer;
