import walletActionTypes from './actionTypes';
import parseError from 'utils/ParseError';

const initialState = {
  data: null,
  loading: false,
  error: null
};

export const walletReducer = (state = initialState, action) => {
  switch (action.type) {
    case walletActionTypes.FUND_WALLET.pending:
    case walletActionTypes.WITHDRAW_TO_BANK.pending: {
      return {
        ...state,
        loading: true,
        error: null
      };
    }

    case walletActionTypes.FUND_WALLET.fulfilled:
    case walletActionTypes.WITHDRAW_TO_BANK.fulfilled: {
      return {
        ...state,
        loading: false,
        error: null
      };
    }

    case walletActionTypes.FUND_WALLET.rejected:
    case walletActionTypes.WITHDRAW_TO_BANK.rejected: {
      return {
        ...state,
        loading: false,
        error: parseError(action.payload)
      };
    }

    case walletActionTypes.GET_WALLET.pending: {
      return {
        ...state,
        loading: true,
        error: null
      };
    }

    case walletActionTypes.GET_WALLET.fulfilled: {
      return {
        ...state,
        loading: false,
        error: null,
        data: action.payload.data.data
      };
    }

    case walletActionTypes.GET_WALLET.rejected: {
      return {
        ...state,
        loading: false,
        error: parseError(action.payload)
      };
    }

    default:
      return state;
  }
};

export default walletReducer;
