import actionTypes from 'utils/actionTypes';

export default {
  GETWARDS: actionTypes('GETWARDS'),
  ADDWARD: actionTypes('ADDWARD'),
  UPDATE_WARD: actionTypes('UPDATE_WARD'),
  GET_SCHOOLS: actionTypes('GET_SCHOOLS'),
  DELETE_WARD: actionTypes('DELETE_WARD'),
  LOAN_WARDS: actionTypes('LOAN_WARDS')
};
