import actionTypes from 'utils/actionTypes';

export default {
  GET_ACCOUNTS: actionTypes('GET_ACCOUNTS'),
  GET_ACCOUNT: actionTypes('GET_ACCOUNT'),
  UPDATE_DEFAULT_ACCOUNT: actionTypes('UPDATE_DEFAULT_ACCOUNT'),
  ADD_ACCOUNT: actionTypes('ADD_ACCOUNT'),
  ADD_ACCOUNT_REQUEST: actionTypes('ADD_ACCOUNT_REQUEST'),
  GET_BANKS: actionTypes('GET_BANKS'),
  RESOLVE_BANK: actionTypes('RESOLVE_BANK'),
  VERIFY_BVN: actionTypes('VERIFY_BVN')
};
