import request from 'utils/api';
import authActionTypes from './actionTypes';

export const signUp = (data) => (dispatch) => {
  const payload = request.post('/core/auth/register', data);
  return dispatch({
    type: authActionTypes.SIGNUP.default,
    payload
  });
};

export const verifyOtp = (data) => (dispatch) => {
  const payload = request.post('/core/auth/otp/verify', data);
  return dispatch({
    type: authActionTypes.VERIFY_OTP.default,
    payload
  });
};

export const verifyPasswordResetOtp = (data) => (dispatch) => {
  const payload = request.post('/core/auth/password/verify-reset', data);
  return dispatch({
    type: authActionTypes.VERIFY_PASSWORD_RESET_OTP.default,
    payload
  });
};

export const resendOtpQuery = (data) => (dispatch) => {
  const payload = request.post('/core/otp/resend', data);
  return dispatch({
    type: authActionTypes.RESEND_OTP.default,
    payload
  });
};

export const verifyPasswordReset = (data) => (dispatch) => {
  const payload = request.post('/core/auth/password/change-reset', data);
  return dispatch({
    type: authActionTypes.VERIFY_PASSWORD_RESET.default,
    payload
  });
};

export const requestResetPassword = (data) => (dispatch) => {
  const payload = request.post('/core/auth/password/reset', data);
  return dispatch({
    type: authActionTypes.RESET_PASSWORD.default,
    payload
  });
};

export const resendRegistrationOtp = (data) => (dispatch) => {
  const payload = request.post('/core/auth/otp/resend', data);
  return dispatch({
    type: authActionTypes.RESEND_REGISTRATION_OTP.default,
    payload
  });
};

export const logIn = (data) => (dispatch) => {
  const payload = request.post('/core/auth/login', data);
  return dispatch({
    type: authActionTypes.LOGIN.default,
    payload
  });
};

export const logout = () => (dispatch) => {
  return dispatch({ type: authActionTypes.RESET });
};

export const reset = () => (dispatch) =>
  dispatch({ type: authActionTypes.RESET });

export const updateProfile = (data) => (dispatch) => {
  const payload = request.patch('/core/user/profile', data);
  return dispatch({
    type: authActionTypes.UPDATE_PROFILE.default,
    payload
  });
};

export const changePassword = (data) => (dispatch) => {
  const payload = request.post('/core/auth/password/change', data);
  return dispatch({
    type: authActionTypes.CHANGE_PASSWORD.default,
    payload
  });
};
//* school dashboard
export const schoolSignUp = (data) => (dispatch) => {
  const payload = request.post('/core/school/onboard', data);
  return dispatch({
    type: authActionTypes.SCHOOL_SIGNUP.default,
    payload
  });
};

export const schlogIn = (data) => (dispatch) => {
  const payload = request.post('/core/auth/school/login', data);
  return dispatch({
    type: authActionTypes.SCHOOL_SIGNIN.default,
    payload
  });
};

export const schoolverifyOtp = (data) => (dispatch) => {
  const payload = request.post('/core/auth/otp/verify', data);
  return dispatch({
    type: authActionTypes.SCHOOL_VERIFY_OTP.default,
    payload
  });
};

export const schoolOnboard = (data) => (dispatch) => {
  const payload = request.post('/core/school/onboard', data);
  return dispatch({
    type: authActionTypes.ONBOARD_SCHOOL.default,
    payload
  });
};

//* MFB
export const signIn = (data) => (dispatch) => {
  const payload = request.post('/core/auth/partner/login', data);
  return dispatch({
    type: authActionTypes.SIGNIN.default,
    payload
  });
};

//* ZOROPAY ADMIN LOGIN
export const zoropaysignIn = (data) => (dispatch) => {
  const payload = request.post('/core/auth/super/login', data);
  return dispatch({
    type: authActionTypes.LOGIN.default,
    payload
  });
};
