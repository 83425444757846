import React from 'react';
import Modal from 'reuseables/Modal';
import Button from 'reuseables/button';
import Stack from 'styles/Stack';

const SessionModal = ({ isOpen, onClose, logOut, stayActive }) => {
  return (
    <>
      <Modal
        children={
          <>
            <h2
              style={{
                fontSize: '16px',
                fontWeight: 'bold',
                textAlign: 'center',
                color: '#1749da',
                marginBottom: '36px'
              }}
            >
              You Have Been Idle
            </h2>

            <div>
              <p
                style={{
                  fontSize: '14px',
                  textAlign: 'center',
                  marginBottom: '10px'
                }}
              >
                Your current session is about to expire. Do you want to extend
                your current session
              </p>
              <Stack distribution="space-between">
                <Button
                  style={{ height: 'unset' }}
                  text="No"
                  width={'165px'}
                  height={'41px'}
                  round
                  mobileWidth={'100px'}
                  outline
                  onClick={logOut}
                />
                <Button
                  style={{ height: 'unset' }}
                  text="Yes"
                  width={'165px'}
                  height={'41px'}
                  round
                  mobileWidth={'100px'}
                  onClick={stayActive}
                />
              </Stack>
            </div>
          </>
        }
        onClose={onClose}
        isOpen={isOpen}
        width={394}
      />
    </>
  );
};

export default SessionModal;
