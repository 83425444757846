import actionTypes from 'utils/actionTypes';

export default {
  SIGNUP: actionTypes('SIGNUP'),
  LOGIN: actionTypes('LOGIN'),
  VERIFY_OTP: actionTypes('VERIFY_OTP'),
  VERIFY_PASSWORD_RESET: actionTypes('VERIFY_PASSWORD_RESET'),
  RESET_PASSWORD: actionTypes('RESET_PASSWORD'),
  VERIFY_PASSWORD_RESET_OTP: actionTypes('VERIFY_PASSWORD_RESET_OTP'),
  RESEND_REGISTRATION_OTP: actionTypes('RESEND_REGISTRATION_OTP'),
  RESET: 'RESET',
  LOGOUT: 'LOGOUT',
  UPDATE_PROFILE: actionTypes('UPDATE_PROFILE'),
  CHANGE_PASSWORD: actionTypes('CHANGE_PASSWORD'),
  RESEND_OTP: actionTypes('RESEND_OTP'),
  //school
  SCHOOL_SIGNUP: actionTypes('SCHOOL_SIGNUP'),
  SCHOOL_SIGNIN: actionTypes('SCHOOL_SIGNIN'),
  ONBOARD_SCHOOL: actionTypes('ONBOARD_SCHOOL'),
  SCHOOL_VERIFY_OTP: actionTypes('SCHOOL_VERIFY_OTP'),
  // MFB
  SIGNIN: actionTypes('SIGNIN')
};
