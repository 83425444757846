import styled, { css } from 'styled-components';

const Stack = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  box-sizing: border-box;
  &::-webkit-scrollbar {
    -webkit-appearance: none;
  }

  &::-webkit-scrollbar:vertical {
    width: 8px;
  }

  &::-webkit-scrollbar:horizontal {
    height: 8px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 8px;
    border: 2px solid white;
    background-color: rgba(0, 0, 0, 0.1);
  }
  ${(props) =>
    props.padding &&
    css`
      padding: ${props.padding};
      ${props.padding === '100%' &&
      css`
        padding: -webkit-fill-available;
      `}
    `}
  ${(props) =>
    props.margin &&
    css`
      margin: ${props.margin};
    `}
  ${(props) =>
    props.height &&
    css`
      height: ${props.height};
      ${props.height === '100%' &&
      css`
        height: -webkit-fill-available;
      `}
    `}
  ${(props) =>
    props.width &&
    css`
      width: ${props.width};
      ${props.width === '100%' &&
      css`
        width: 100%;
      `}
    `}
    ${(props) =>
    props.maxWidth &&
    css`
      max-width: ${props.maxWidth};
      ${props.maxWidth === '100%' &&
      css`
        max-width: 100%;
      `}
    `}
    ${(props) =>
    props.minWidth &&
    css`
      min-width: ${props.minWidth};
    `}
  ${(props) =>
    props.horizontal &&
    css`
      flex-direction: row;
    `}
  ${(props) =>
    props.itemMargin &&
    css`
      & > * {
        margin: 8px;
      }
    `}
  ${(props) =>
    props.vertical &&
    css`
      flex-direction: column;
    `}
  ${(props) =>
    props.distribution &&
    css`
      justify-content: ${props.distribution};
    `}
  ${(props) =>
    props.wrap &&
    css`
      flex-wrap: wrap;
    `}
  ${(props) =>
    props.alignment &&
    css`
      align-items: ${props.alignment};
    `}
  ${(props) =>
    props.horizontalScroll &&
    css`
      overflow-x: scroll;
    `}
  ${(props) =>
    props.veriticalScroll &&
    css`
      overflow-y: scroll;
    `}
    ${(props) =>
    props.gap &&
    css`
      gap: ${props.gap};
    `}
  @media only screen and (max-width: 720px) {
    /* width: 100% !important; */
    border: none !important;
    ${(props) =>
      props.distributionMobile &&
      css`
        justify-content: ${props.distributionMobile};
      `}
    ${(props) =>
      props.widthMobile &&
      css`
        width: ${props.widthMobile} !important;
        ${props.widthMobile === '100%' &&
        css`
          width: 100%;
        `}
      `}
      ${(props) =>
      props.heightMobile &&
      css`
        height: ${props.heightMobile} !important;
        ${props.heightMobile === '100%' &&
        css`
          width: 100%;
        `}
      `}
    ${(props) =>
      props.directionMobile &&
      css`
        flex-direction: ${props.directionMobile};
      `}
    ${(props) =>
      props.alignmentMobile &&
      css`
        align-items: ${props.alignmentMobile};
      `}
    ${(props) =>
      props.wrapMobile &&
      css`
        flex-wrap: wrap;
      `}
  }

  @media (max-width: 980px) {
    ${(props) =>
      props.widthMdMobile &&
      css`
        width: ${props.widthMdMobile} !important;
        ${props.widthMdMobile === '100%' &&
        css`
          width: 100%;
        `}
      `}
  }
`;

export default Stack;
