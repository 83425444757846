import actionTypes from 'utils/actionTypes';

export default {
  CREATE_PLAN: actionTypes('CREATE_PLAN'),
  GET_PLANS: actionTypes('GET_PLANS'),
  PLANS: 'PLANS',
  CREATE_NOTIFICATION: 'CREATE_NOTIFICATION',
  GET_ACTIVE_PLANS: actionTypes('GET_ACTIVE_PLANS'),
  GET_COMPLETED_PLANS: actionTypes('GET_COMPLETED_PLANS'),
  UPDATE_PLAN: actionTypes('UPDATE_PLAN'),
  FUND_PLAN: actionTypes('FUND_PLAN'),
  WITHDRAW_FUNDS: actionTypes('WITHDRAW_FUNDS'),
  PLAN_ANALYTICS: actionTypes('PLAN_ANALYTICS'),
  CREATE_POCKET_MONEY_PLAN: actionTypes('CREATE_POCKET_MONEY_PLAN'),
  GET_POCKET_MONEY_PLANS: actionTypes('GET_POCKET_MONEY_PLANS'),
  GET_POCKET_MONEY_PLAN: actionTypes('GET_POCKET_MONEY_PLAN'),
  FUND_POCKET_MONEY_PLAN: actionTypes('FUND_POCKET_MONEY_PLAN'),
  DELETE_POCKET_MONEY_PLAN: actionTypes('DELETE_POCKET_MONEY_PLAN'),
  UPDATE_POCKET_MONEY_PLAN: actionTypes('UPDATE_POCKET_MONEY_PLAN')
};
