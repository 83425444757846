import userActionTypes from './actionTypes';
import parseError from 'utils/ParseError';

const initialState = {
  user: null,
  pwa: null,
  loading: false,
  error: null,
  actionLoading: false
};

export const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case userActionTypes.GET_USER.pending:
    case userActionTypes.GET_APP_VERSION.pending:
    case userActionTypes.GET_PROFILE.pending: {
      return {
        ...state,
        loading: true,
        error: null
      };
    }

    case userActionTypes.GET_APP_VERSION.fulfilled: {
      return {
        ...state,
        loading: false,
        error: null,
        pwa: action.payload.data.data.version
      };
    }

    case userActionTypes.GET_USER.fulfilled:
    case userActionTypes.GET_PROFILE.fulfilled: {
      return {
        ...state,
        loading: false,
        error: null,
        user: action.payload.data.data.user
      };
    }

    case userActionTypes.GET_USER.rejected:
    case userActionTypes.GET_PROFILE.rejected: {
      return {
        ...state,
        loading: false,
        error: parseError(action.payload)
      };
    }

    case userActionTypes.UPDATE_PROFILE.pending: {
      return {
        ...state,
        actionLoading: true,
        error: null
      };
    }

    case userActionTypes.UPDATE_PROFILE.fulfilled: {
      return {
        ...state,
        actionLoading: false,
        error: null,
        user: action.payload.data.data
      };
    }

    case userActionTypes.UPDATE_PROFILE.rejected: {
      return {
        ...state,
        actionLoading: false,
        error: parseError(action.payload)
      };
    }

    default:
      return state;
  }
};

export default userReducer;
