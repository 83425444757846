import React, { useRef, useState } from 'react';
import IdleTimer from 'react-idle-timer';
import useAuth from 'hooks/useAuth';
import SessionModal from './SessionModal';
import { store } from 'store';
import { logout } from 'store/auth/actions';

const IdleTimerContainer = () => {
  const [showUploadModal, setShowUploadModal] = useState(false);
  const toggleModal = (toggleFunc) => toggleFunc((s) => !s);

  const idleTimerRef = useRef(null);
  const sessionTimeoutRef = useRef(null);

  const onIdle = () => {
    // console.log('user is idle');
    if (isAuthenticated) {
      setShowUploadModal(true);
      sessionTimeoutRef.current = setTimeout(logOut, 120000);
    }
  };

  const stayActive = () => {
    setShowUploadModal(false);
    clearTimeout(sessionTimeoutRef.current);
  };

  const logOut = () => {
    // console.log('Logged Out');
    store.dispatch(logout());
    setShowUploadModal(false);
    clearTimeout(sessionTimeoutRef.current);
    window.location.reload(true);
  };
  //* Check if user is signed in
  const isAuthenticated = useAuth();
  return (
    <div>
      <IdleTimer ref={idleTimerRef} timeout={480000} onIdle={onIdle} />

      <SessionModal
        isOpen={showUploadModal}
        onClose={() => toggleModal(setShowUploadModal)}
        logOut={logOut}
        stayActive={stayActive}
      />
    </div>
  );
};

export default IdleTimerContainer;
